#login_page {
	display:grid;
	font-family: Open Sans,sans-serif;
}
#login{
	height:100%;
	background: url("/images/app/hero_image.webp");
	background-size: cover;
    background-position: center;
}
#login_page{
	/******** margin-test*********/
.mt-4{
    margin-top: 15px!important;
}
.mb-4{
    margin-bottom: 15px!important;
}
.mt-5{
    margin-top: 30px!important;
}
.mt-6{
    margin-top: 45px!important;
}
.mb-5{
    margin-bottom: 30px!important;
}
	.header-logo {
		height:45px;
	}
	.login-form-space{
		border-radius: 0;
		border: 1px solid  #f7a400;
		background:#000;
		padding:30px;
		.login-header{
			background: transparent;
			border-bottom:0;
			color:#fff;
			margin: 0 auto;
			font-size: 18px;
			letter-spacing: 0.5px;
		}
	}
	.required{
		font-size:12px;
		color:red;
		margin-right: 7px;
		vertical-align: top;
	}
	.forgot-password{
		color:#f7a400!important;
		text-decoration: none;
		font-weight: 400;
		line-height: 2;
		padding: 0;
		font-size: 16px;	
		cursor: pointer;
	}
	.btn-primary{
		width: 100%;
		border: 1px solid #FF0000!important;
		border-radius: 0;
		background-color: #FF0000!important;
		color: #fff!important;
		box-shadow: none;
		padding: 15px;
		font-size: 16px;
		height: auto;
		margin-top: 15px;
		text-transform: none;
	}
	.btn-primary.black {
		background-color: black !important;
		font-size: 14px;
	}
	input[type="email"], input[type="password"] {
		box-shadow: none;
		border:0;
		border-bottom: 1px solid #f7a400!important;
		color: #fff!important;
		background:#000;
		border-radius:0;
		margin-bottom: 0px;
	}
	.label--checkbox {
		font-size: 14px;
		letter-spacing: 0;
		color: #fff;
		margin:0;
	.card-header {
		font-size: 20px;
		font-weight: 600;
		font-family: var(--bs-font-lato-sans);
		color: var(--bs-black);
	}
}


@media(max-width:768px){
	#login_page .login-form-space .card-body {
		padding: 15px;
		margin: 0;
	}
	#login_page .login-form-space .card-body label, #login_page .forgot-password {
		font-size: 14px;
	}
	#login_page .checkbox-template {
		margin-top: 2px;
		margin-right:15px;
	}
	#login_page .login-form-space .card-header {
		padding:15px 15px 0 15px;
	}
}
.form-outline .form-control:focus~.form-notch .form-notch-leading, .form-outline .form-control:focus~.form-notch .form-notch-middle, .form-outline .form-control:focus~.form-notch .form-notch-trailing{
    border-color: #f7a400;
    box-shadow: none!important;
	border-top: 0;
}
.form-outline .form-control~.form-notch div {
	border:0;
	border-bottom:1px solid #f7a400;
	border-radius: 0!important;
	box-shadow:none!important;
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: red!important;
}
.form-outline {
	.form-control {
		&:focus~.form-label {
			color: #f7a400;
			left:0;
			border:0!important;
			top: 0;
		}
		&.active~.form-label {
			top: 0;
		}
	}
	.form-control~.form-label {
		color: #f7a400;
		font-size:16px;
		padding:0px;
		left: 0;
		top: 10px;
		@media(max-width:768px){
			overflow: initial;
			white-space: break-spaces;
		}
	}
}
@-webkit-keyframes autofill {
    0%,100% {
        color: #000;
        background: transparent;
    }
}
input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}
input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent!important;
    color: #fff !important;
}
.label--checkbox {
  position: relative;
  margin: 0.5rem;
  font-family: Arial, sans-serif;
  line-height: 135%;
  display: inline;
  cursor: pointer;
}

.checkbox {
	position: relative;
	margin: 0 1rem 0 0;
	cursor: pointer;
}
.checkbox:before {
	content: "";
	position: absolute;
	left: 0;
	z-index: 1;
	width: 18px;
	height: 18px;
	border: 2px solid #f7a400;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.checkbox:checked:before {
	height: 0.5rem;
	border-color: #f7a400;
	border-top-style: none;
	border-right-style: none;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.checkbox:after {
	content: "";
	position: absolute;
	top: -0.125rem;
	left: 0;
	width: 1.1rem;
	height: 1.1rem;
	background: #000;
}
@keyframes spinner-plncf9 {
	100% {
	   transform: rotate(1turn);
	}
 }
 
 @-webkit-keyframes slideUp {
   0% {
	 -webkit-transform: translateY(6.25rem);
	 transform: translateY(6.25rem);
   }
   100% {
	 -webkit-transform: translateY(0);
	 transform: translateY(0);
   }
 }
 @keyframes slideUp {
   0% {
	 -webkit-transform: translateY(6.25rem);
	 transform: translateY(6.25rem);
   }
   100% {
	 -webkit-transform: translateY(0);
	 transform: translateY(0);
   }
 }

.form-control{
	height: calc(1.5em + 1.25rem + 5px);
    margin-bottom: 0!important;
    padding: 0!important;
    color: #fff;
}
.form-control:focus {
	color: #f7a400;
	background-color: #000;
	//border-color:#000!important;
}
.form-outline .form-control:focus~.form-label {
	color: #f7a400;
	left:0;
	border:0!important;
}
.form-outline .form-control~.form-label {
	color: #f7a400;
	font-size:16px;
	padding:0px;
	left: 0;
}
.form-check-input[type=radio]:checked:after {
	border-color: #f7a400;
    background-color: #f7a400;
    width: 0.645rem;
}
.form-check-input[type=radio]:after {
    background-color: #000;
}
.form-check-input, .form-check-input:focus {
	border-color: #f7a400;
	background-color: #000;
	border: 0.145rem solid #f7a400;
}
.form-check-input[type=radio]:checked, .form-check-input[type=radio]:checked:focus {
    background-color: #000;
}
.form-check-input:checked, .form-check-input:checked:focus {
    border-color: #f7a400;
}
input[type=radio]:not(:checked)+span:after,
input[type=radio]:not(:checked)+span:before
{
    border: 2px solid #f7a400;
}
.form-check-input[type=radio] {
    width: 1.45rem;
    height: 1.4rem;
}
input[type=radio].with-gap:checked+span:after,
input[type=radio]:checked+span:after{
    background-color: #f7a400;
}

input[type=radio].with-gap:checked+span:after,
input[type=radio].with-gap:checked+span:before,
input[type=radio]:checked+span:after{
    border: 2px solid #f7a400;
}
.tooltip {
    text-align: left;
	border:1px solid #f7a400;
    font-size:14px;
	line-height:1.5;
	padding:15px;
	color:#fff;
	background:#000;	
	max-width:350px;
	min-width:250px;
}
.tooltip .tooltip-inner{
	width:100%;
	padding:0;
	max-width: 100%;
}
.form-outline .form-control:focus~.form-notch .form-notch-leading, .form-outline .form-control:focus~.form-notch .form-notch-middle, .form-outline .form-control:focus~.form-notch .form-notch-trailing{
    border-color: #f7a400;
    box-shadow: none!important;
	border-top: 0;
}
.form-outline .form-control~.form-notch div {
	border:0;
	border-bottom:1px solid #f7a400;
	border-radius: 0!important;
	box-shadow:none!important;
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: red!important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: #fff;
  opacity: 1;
  background-color: #000;
  box-shadow: inset 0 0 0 220px #000 !important;
  -webkit-text-fill-color: #fff;
}
input:-webkit-autofill {
  color: #fff;
  opacity: 1;
  background-color: #000;
  box-shadow: inset 0 0 0 220px #000 !important;
  -webkit-text-fill-color: #fff;
}
input:-internal-autofill-selected {
  color: #fff;
  opacity: 1;
  background-color: #000;
  box-shadow: inset 0 0 0 220px #000 !important;
  -webkit-text-fill-color: #fff;
}
input:-webkit-autofill:focus {
  color: #fff!important;
  opacity: 1!important;
  background-color: #000!important;
  box-shadow: 0 0 0 1000px #000 inset!important;
  -webkit-text-fill-color: #fff!important;
} 

::-webkit-input-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}

::-moz-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}

:-ms-input-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}

::-ms-input-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}
}

#socials{
	.login-by{
		a{
			color:#fff;
			font-size:16px;
			font-weight:600;
			text-decoration:none;
			display:flex;
			p {
				&.text {
					line-height: 50px;
					margin-bottom: 0;
				}
			}
		}
		.icon{
			padding:10px;
			background:#fff;
			margin-right:15px;
		}
		width:100%;
		&.facebook{
			background:#3b5998;
		}
		&.google{
			background:#4285f4;
		}
	}
}
.clausule{
	a{
		color: #f7a400;
    	text-decoration: none;
	}
	a:active, a:focus, a:hover {
		color: #f7a400;
   		opacity: .64;
	}
	.open-more-text {
		color: #f7a400;
		cursor: pointer;
		text-decoration: none;
		text-wrap: nowrap;
	}
	.read-more-clausule{
		color: #fff;
    	font-size: 12px;
	}
}